
import { Component, Vue } from 'vue-property-decorator';
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
} from '@/interfaces';
//import 'babel-polyfill'; // es6 shim
import { dispatchCreateUser } from '@/store/main/actions';
import myUpload from 'vue-image-crop-upload/upload-2.vue';


@Component({
  components: {
    'my-upload': myUpload
  }
})
export default class CreateUser extends Vue {

  public valid = false;
  public firstname: string = '';
  public lastname: string = '';
  public email: string = '';
  public static isActive: boolean = false;
  public static isSuperuser: boolean = false;
  public setPassword = false;
  public password1: string = '';
  public password2: string = '';
  public gender: string = '';
  public road: string = '';
  public house_number: string = '';
  public postcode: number = 0;
  public city: string= '';
  public birthplace: string = '';
  public birthday: Date = new Date(1999, 4, 13);
  public nationality: string = '';
  public language = true;

  public numberRules = [
    v => (v > 1000 || v < 1000000) || 'Please enter an valid postcode'
  ];
  public numberRules_german = [
    v => (v > 1000 || v < 1000000) || 'Bitte gib eine gültige PLZ ein'
  ];

  public items = ["male", "female", "divers"];
  public items_german = ["männlich", "weiblich", "divers"];

  public async mounted() {
    this.reset();
  }
  
  //For imageCropUpload----------------
  public show = false;
  public imgDataUrl = '';
  public params = {
    token: '123456789',
    name: 'avatar'
  };
  public headers = {
    smail: '*_~'
  }

  public de = {
        hint: 'Click or drag the file here to upload',
        loading: 'Uploading…',
        noSupported: 'Dieser Browser wird nicht unterstützt, bitte benutzen sie IE10+ oder vergleichbare.',
        success: 'Hochladen erfolgreich',
        fail: 'Hochladen fehlgeschlagen',
        preview: 'Vorschau',
        btn: {
          off: 'Abbrechen',
          close: 'Schließen',
          back: 'Zurück',
          save: 'Sichern'
        },
        error: {
          onlyImg: 'Nur Bilder',
          outOfSize: 'Bild ist zu groß. Limit ist: ',
          lowestPx: 'Bilder Größe ist zu gering. Mindestgröße ist: '
        }
  }

  public en = {
        hint: 'Click or drag the file here to upload',
        loading: 'Uploading…',
        noSupported: 'Browser is not supported, please use IE10+ or other browsers',
        success: 'Upload success',
        fail: 'Upload failed',
        preview: 'Preview',
        btn: {
          off: 'Cancel',
          close: 'Close',
          back: 'Back',
          save: 'Save'
        },
        error: {
          onlyImg: 'Image only',
          outOfSize: 'Image exceeds size limit: ',
          lowestPx: 'Image\'s size is too low. Expected at least: '
        }
  }

  get text() {
    return this.de
    if(this.language) {
     return this.en;
    } else return this.de;
  }

  public toggleShow() {
    this.show = !this.show;
  }

	cropSuccess(imgDataUrl, field){
    console.log("Here the b64: " + imgDataUrl)
		console.log('-------- crop success --------');
		this.imgDataUrl = imgDataUrl;
	}

  cropUploadSuccess(jsonData, field){
		console.log('-------- upload success --------');
		console.log(jsonData);
		console.log('field: ' + field);
	}

  cropUploadFail(status, field){
				console.log('-------- upload fail --------');
				console.log(status);
				console.log('field: ' + field);
	}

  //Should not be used at the time -> imgDataUrl is already a b64
  public async b64Helper(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }

  //---------------------------------------

  public reset() {
    this.password1 = '';
    this.password2 = '';
    this.firstname = '';
    this.lastname = '';
    this.email = '';
    this.gender = '';
    this.city = '';
    this.road = '';
    this.house_number = '';
    this.postcode = 0;
    this.birthplace = '';
    this.birthday = new Date(1999, 4, 13);
    this.nationality = '';
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }
  // TODO: Check if the email already exists
  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IUserProfileCreate = {
        email: this.email,
      };
      if (this.firstname) {
        updatedProfile.firstname = this.firstname;
      }
      if (this.lastname) {
        updatedProfile.lastname = this.lastname;
      }
      if (this.email) {
        updatedProfile.email = this.email;
      }
      // if (this.age) {
      //   updatedProfile.age = this.age;
      // }
      if (this.road) {
        updatedProfile.road = this.road;
      }
      if (this.house_number) {
        updatedProfile.house_number = this.house_number;
      }
      if (this.postcode) {
        updatedProfile.postcode = this.postcode;
      }
      if (this.gender) {
        updatedProfile.gender = this.gender;
      }
      if (this.birthday.toString() != new Date(1999, 4, 13).toString()) {
        updatedProfile.birthday = this.birthday;
      }
      if (this.nationality) {
        updatedProfile.nationality = this.nationality;
      }
      if (this.city) {
        updatedProfile.city = this.city;
      }
      updatedProfile.is_active = false;
      updatedProfile.is_superuser = false;
      updatedProfile.password = this.password1;
      if (this.imgDataUrl) {
        let b64 = this.imgDataUrl;
        try {
          await dispatchCreateUser(this.$store, {user: updatedProfile, file: b64 as string});
        } catch (e) {

        }
      } else {
        await dispatchCreateUser(this.$store, {user: updatedProfile, file: "null"});
      }
      this.$router.push('login');
    }
  }
/*  get language() {
    return readLanguage(this.$store);
  }
  
  public swtichLanguageEng() {
    console.log("Englisch");
    //True means eng
    if(this.language) {
      commitSwitchLanguage(this.$store);
    }
  }

  public swtichLanguageDe() {
    //False means de
    console.log("DEUTSCH");
    if(!this.language) {
      commitSwitchLanguage(this.$store);
    }
  }*/

}
  
